import { Outlet, Navigate } from 'react-router-dom'
import useAuth from '../helpers/useAuth';

import UnauthenticatedLayout from '../components/UnauthenticatedLayout'

const UnauthenticatedLayoutRoute = () => {
    const user = useAuth();
    if (user === 'Loading') return <div>Loading</div>
    if (!!user) return <Navigate to={'/'}/>
    return (
      <UnauthenticatedLayout>
        <Outlet />
      </UnauthenticatedLayout>
    )
}

export default UnauthenticatedLayoutRoute
