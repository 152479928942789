import { Outlet, Navigate } from 'react-router-dom'

import MainLayout from '../components/MainLayout'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import useAuth from '../helpers/useAuth'



const MainLayoutRoute = () => {
    const user = useAuth();
    if (user === 'Loading') return <div>Loading</div>
    if (!user) return <Navigate to={'/login'}/>
    return (
      <MainLayout
        Footer={<Footer />}
        Navbar={<Navbar />}
        Sidebar={<Sidebar />}
      >
        <Outlet />
      </MainLayout>
    )
}

export default MainLayoutRoute
