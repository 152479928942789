const purples = {
    P100: '#E5DFFB',
    P200: '#CCC0F8',
    P300: '#AB9CEC',
    P400: '#8D7DD9',
    P500: '#6554C0',
    P600: '#4C3DA5',
    P700: '#362A8A',
    P800: '#231A6F',
    P900: '#17105C'
  }
  
  const purpleAlphas = {
    PA4: 'rgba(101, 84, 192, 0.04)',
    PA8: 'rgba(101, 84, 192, 0.08)',
    PA16: 'rgba(101, 84, 192, 0.16)',
    PA24: 'rgba(101, 84, 192, 0.24)',
    PA32: 'rgba(101, 84, 192, 0.32)',
    PA40: 'rgba(101, 84, 192, 0.40)',
    PA48: 'rgba(101, 84, 192, 0.48)'
  }
  
  const greens = {
    G100: '#85E1CB',
    G200: '#66D9BE',
    G300: '#47D1B1',
    G400: '#29CAA4',
    G500: '#0AC297',
    G600: '#09AA84',
    G700: '#089271',
    G800: '#06795E',
    G900: '#05614C'
  }
  
  const greenAlphas = {
    GA4: 'rgba(10, 194, 151, 0.04)',
    GA8: 'rgba(10, 194, 151, 0.08)',
    GA16: 'rgba(10, 194, 151, 0.16)',
    GA24: 'rgba(10, 194, 151, 0.24)',
    GA32: 'rgba(10, 194, 151, 0.32)',
    GA40: 'rgba(10, 194, 151, 0.40)',
    GA48: 'rgba(10, 194, 151, 0.48)'
  }
  
  const blues = {
    B100: '#99B3FF',
    B200: '#809FFF',
    B300: '#668CFF',
    B400: '#4D79FF',
    B500: '#3366FF',
    B600: '#2D59DF',
    B700: '#264DBF',
    B800: '#20409F',
    B900: '#1A3380'
  }
  
  const blueAlphas = {
    BA4: 'rgba(51, 102, 255, 0.04)',
    BA8: 'rgba(51, 102, 255, 0.08)',
    BA16: 'rgba(51, 102, 255, 0.16)',
    BA24: 'rgba(51, 102, 255, 0.24)',
    BA32: 'rgba(51, 102, 255, 0.32)',
    BA40: 'rgba(51, 102, 255, 0.40)',
    BA48: 'rgba(51, 102, 255, 0.48)'
  }
  
  const lightBlues = {
    LB100: '#D7F4FE',
    LB200: '#B0E6FE',
    LB300: '#88D3FE',
    LB400: '#6BC0FD',
    LB500: '#3AA1FC',
    LB600: '#2A7DD8',
    LB700: '#1D5DB5',
    LB800: '#124292',
    LB900: '#0B2E78'
  }
  
  const lightBlueAlphas = {
    LBA4: 'rgba(58, 161, 252, 0.04)',
    LBA8: 'rgba(58, 161, 252, 0.08)',
    LBA16: 'rgba(58, 161, 252, 0.16)',
    LBA24: 'rgba(58, 161, 252, 0.24)',
    LBA32: 'rgba(58, 161, 252, 0.32)',
    LBA40: 'rgba(58, 161, 252, 0.40)',
    LBA48: 'rgba(58, 161, 252, 0.48)'
  }
  
  const yellows = {
    Y100: '#FFEAB6',
    Y200: '#FFE5A4',
    Y300: '#FFE092',
    Y400: '#FFDA7F',
    Y500: '#FFD56D',
    Y600: '#DFBA5F',
    Y700: '#BFA052',
    Y800: '#9F8544',
    Y900: '#806B37'
  }
  
  const yellowAlphas = {
    YA4: 'rgba(255, 213, 109, 0.04)',
    YA8: 'rgba(255, 213, 109, 0.08)',
    YA16: 'rgba(255, 213, 109, 0.16)',
    YA24: 'rgba(255, 213, 109, 0.24)',
    YA32: 'rgba(255, 213, 109, 0.32)',
    YA40: 'rgba(255, 213, 109, 0.40)',
    YA48: 'rgba(255, 213, 109, 0.48)'
  }
  
  const reds = {
    R100: '#FF8DA9',
    R200: '#FF7094',
    R300: '#FF537E',
    R400: '#FF3769',
    R500: '#FF1A53',
    R600: '#DF1749',
    R700: '#BF143E',
    R800: '#9F1034',
    R900: '#800D2A'
  }
  
  const redAlphas = {
    RA4: 'rgba(255, 26, 83, 0.04)',
    RA8: 'rgba(255, 26, 83, 0.08)',
    RA16: 'rgba(255, 26, 83, 0.16)',
    RA24: 'rgba(255, 26, 83, 0.24)',
    RA32: 'rgba(255, 26, 83, 0.32)',
    RA40: 'rgba(255, 26, 83, 0.40)',
    RA48: 'rgba(255, 26, 83, 0.48)'
  }
  
  export const neutrals = {
    N900: '#091E42',
    N800: '#172B4D',
    N700: '#253858',
    N600: '#344563',
    N500: '#42526E',
    N400: '#505F79',
    N300: '#5E6C84',
    N200: '#6B778C',
    N100: '#7A869A',
    N90: '#8993A4',
    N80: '#97A0AF',
    N70: '#A5ADBA',
    N60: '#B3BAC5',
    N50: '#C1C7D0',
    N40: '#DFE1E6',
    N30: '#EBECF0',
    N20: '#F4F5F7',
    N10: '#FAFBFC',
    N0: '#FFFFFF'
  }
  
  export const neutralAlphas = {
    NA48: 'rgba(9, 30, 66, 0.48)',
    NA40: 'rgba(9, 30, 66, 0.40)',
    NA32: 'rgba(9, 30, 66, 0.32)',
    NA24: 'rgba(9, 30, 66, 0.24)',
    NA16: 'rgba(9, 30, 66, 0.16)',
    NA12: 'rgba(9, 30, 66, 0.12)',
    NA8: 'rgba(9, 30, 66, 0.08)',
    NA4: 'rgba(9, 30, 66, 0.04)'
  }

  const colors = {
    ...purples,
    ...purpleAlphas,
    ...greens,
    ...greenAlphas,
    ...blues,
    ...blueAlphas,
    ...lightBlues,
    ...lightBlueAlphas,
    ...yellows,
    ...yellowAlphas,
    ...reds,
    ...redAlphas,
    ...neutrals,
    ...neutralAlphas,
    COLORNAME: 'fuschia'
  };
  
  export default colors;
  