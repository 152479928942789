import styled from '../../styles'

import { faLock } from '@fortawesome/free-solid-svg-icons';
import Button from '../../elements/Button'
import Card, { Body, Heading } from '../../elements/Card'
import Link from '../../elements/Link'
import Text from '../../elements/Text';

import SingleColumnLayout from '../../components/SingleColumnLayout';

import Form from '../../form';
import TextField from '../../form/TextField';
import { Auth } from 'aws-amplify';

const Wrapper = styled('div', {
  padding: '10vh 0'
});

const initialValues = { email: '', password: '' };

const Login = () => {
  const handleSubmit = ({ email, password }, { setFormError }) => {
    return Auth.signIn(email, password)
        .catch(({ message }) => setFormError('INVALID_LOGIN', {
            icon: faLock,
            title: message,
            status: 'warning'
        }));
  };

  return (
    <SingleColumnLayout size='sm'>
      <Wrapper>
        <Card>
          <Heading>
            <Text size='lg' weight='medium'>
              Sign in
            </Text>
          </Heading>
          <Body>
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <TextField
                required
                autoComplete='email'
                fieldId='email'
                label='Email address'
                placeholder='Email address'
                type='email'
              />
              <TextField
                required
                autoComplete='current-password'
                css={{ letterSpacing: '1px' }}
                fieldId='password'
                label='Password'
                placeholder='&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;'
                type='password'
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                <Link to='/forgot-password' size='lg'>
                  Forgot password?
                </Link>
              </div>
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginTop: '1.5rem' }}>
                <div style={{ marginBottom: '-1.5rem' }} />
                <Button>
                  Sign in
                </Button>
              </div>
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
                <Link to='/signup' size='lg'>
                  Don't have an account? <span style={{ textDecoration: 'underline' }}>Sign up</span>
                </Link>
              </div>
            </Form>
          </Body>
        </Card>
      </Wrapper>
    </SingleColumnLayout>
  )
}

export default Login
