import colors from '../colors'

const theme = {
  basicFocus: colors.NA16,
  basicHover: colors.NA8,
  basicDefault: colors.NA12,
  basicActive: colors.NA16,
  basicDisabled: colors.NA4,
  basicFocusBorder: colors.N40,
  basicHoverBorder: colors.N20,
  basicDefaultBorder: colors.N50,
  basicActiveBorder: colors.N40,
  basicDisabledBorder: colors.N30,

  basicTransparentFocus: colors.NA16,
  basicTransparentHover: colors.NA8,
  basicTransparentDefault: colors.NA4,
  basicTransparentActive: colors.NA16,
  basicTransparentDisabled: colors.NA4,
  basicTransparentFocusBorder: colors.N200,
  basicTransparentHoverBorder: colors.N200,
  basicTransparentDefaultBorder: colors.N200,
  basicTransparentActiveBorder: colors.N200,
  basicTransparentDisabledBorder: colors.N50,

  primaryFocus: colors.B600,
  primaryHover: colors.B400,
  primaryDefault: colors.B500,
  primaryActive: colors.B600,
  primaryDisabled: colors.BA40,
  primaryFocusBorder: colors.BA16,
  primaryHoverBorder: colors.B300,
  primaryDefaultBorder: colors.B400,
  primaryActiveBorder: colors.B500,
  primaryDisabledBorder: colors.BA40,

  primaryTransparentFocus: colors.BA16,
  primaryTransparentHover: colors.BA8,
  primaryTransparentDefault: colors.BA4,
  primaryTransparentActive: colors.BA16,
  primaryTransparentDisabled: colors.BA8,
  primaryTransparentFocusBorder: colors.B500,
  primaryTransparentHoverBorder: colors.B500,
  primaryTransparentDefaultBorder: colors.B500,
  primaryTransparentActiveBorder: colors.B500,
  primaryTransparentDisabledBorder: colors.BA32,

  infoFocus: colors.LB600,
  infoHover: colors.LB400,
  infoDefault: colors.LB500,
  infoActive: colors.LB600,
  infoDisabled: colors.LBA40,
  infoFocusBorder: colors.LBA16,
  infoHoverBorder: colors.LB300,
  infoDefaultBorder: colors.LB400,
  infoActiveBorder: colors.LB500,
  infoDisabledBorder: colors.LBA40,

  infoTransparentFocus: colors.LBA16,
  infoTransparentHover: colors.LBA8,
  infoTransparentDefault: colors.LBA4,
  infoTransparentActive: colors.LBA16,
  infoTransparentDisabled: colors.LBA8,
  infoTransparentFocusBorder: colors.LB500,
  infoTransparentHoverBorder: colors.LB500,
  infoTransparentDefaultBorder: colors.LB500,
  infoTransparentActiveBorder: colors.LB500,
  infoTransparentDisabledBorder: colors.LBA32,

  successFocus: colors.G600,
  successHover: colors.G400,
  successDefault: colors.G500,
  successActive: colors.G600,
  successDisabled: colors.GA40,
  successFocusBorder: colors.G400,
  successHoverBorder: colors.G200,
  successDefaultBorder: colors.G300,
  successActiveBorder: colors.G400,
  successDisabledBorder: colors.GA40,

  successTransparentFocus: colors.GA16,
  successTransparentHover: colors.GA8,
  successTransparentDefault: colors.GA4,
  successTransparentActive: colors.GA16,
  successTransparentDisabled: colors.GA8,
  successTransparentFocusBorder: colors.G500,
  successTransparentHoverBorder: colors.G500,
  successTransparentDefaultBorder: colors.G500,
  successTransparentActiveBorder: colors.G500,
  successTransparentDisabledBorder: colors.GA32,

  warningFocus: colors.Y600,
  warningHover: colors.Y400,
  warningDefault: colors.Y500,
  warningActive: colors.Y600,
  warningDisabled: colors.YA40,
  warningFocusBorder: colors.YA24,
  warningHoverBorder: colors.Y300,
  warningDefaultBorder: colors.Y400,
  warningActiveBorder: colors.Y500,
  warningDisabledBorder: colors.YA40,

  warningTransparentFocus: colors.YA16,
  warningTransparentHover: colors.YA8,
  warningTransparentDefault: colors.YA4,
  warningTransparentActive: colors.YA16,
  warningTransparentDisabled: colors.YA4,
  warningTransparentFocusBorder: colors.Y500,
  warningTransparentHoverBorder: colors.Y500,
  warningTransparentDefaultBorder: colors.Y500,
  warningTransparentActiveBorder: colors.Y500,
  warningTransparentDisabledBorder: colors.Y400,

  dangerFocus: colors.R600,
  dangerHover: colors.R400,
  dangerDefault: colors.R500,
  dangerActive: colors.R600,
  dangerDisabled: colors.RA40,
  dangerFocusBorder: colors.RA16,
  dangerHoverBorder: colors.R200,
  dangerDefaultBorder: colors.R300,
  dangerActiveBorder: colors.R400,
  dangerDisabledBorder: colors.RA40,

  dangerTransparentFocus: colors.RA16,
  dangerTransparentHover: colors.RA8,
  dangerTransparentDefault: colors.RA4,
  dangerTransparentActive: colors.RA16,
  dangerTransparentDisabled: colors.RA8,
  dangerTransparentFocusBorder: colors.R500,
  dangerTransparentHoverBorder: colors.R500,
  dangerTransparentDefaultBorder: colors.R500,
  dangerTransparentActiveBorder: colors.R500,
  dangerTransparentDisabledBorder: colors.RA32,

  controlFocus: colors.COLORNAME,
  controlHover: colors.COLORNAME,
  controlDefault: colors.COLORNAME,
  controlActive: colors.COLORNAME,
  controlDisabled: colors.COLORNAME,
  controlFocusBorder: colors.COLORNAME,
  controlHoverBorder: colors.COLORNAME,
  controlDefaultBorder: colors.COLORNAME,
  controlActiveBorder: colors.COLORNAME,
  controlDisabledBorder: colors.COLORNAME,

  controlTransparentFocus: colors.COLORNAME,
  controlTransparentHover: colors.COLORNAME,
  controlTransparentDefault: colors.COLORNAME,
  controlTransparentActive: colors.COLORNAME,
  controlTransparentDisabled: colors.COLORNAME,
  controlTransparentFocusBorder: colors.COLORNAME,
  controlTransparentHoverBorder: colors.COLORNAME,
  controlTransparentDefaultBorder: colors.COLORNAME,
  controlTransparentActiveBorder: colors.COLORNAME,
  controlTransparentDisabledBorder: colors.COLORNAME,

  backgroundBasicLevel1: colors.COLORNAME,
  backgroundBasicLevel2: colors.COLORNAME,
  backgroundBasicLevel3: colors.COLORNAME,
  backgroundBasicLevel4: colors.COLORNAME,

  backgroundAlternativeLevel1: colors.COLORNAME,
  backgroundAlternativeLevel2: colors.COLORNAME,
  backgroundAlternativeLevel3: colors.COLORNAME,
  backgroundAlternativeLevel4: colors.COLORNAME,

  borderBasicLevel1: colors.N600,
  borderBasicLevel2: colors.N200,
  borderBasicLevel3: colors.N40,
  borderBasicLevel4: colors.NA24,

  borderAlternativeLevel1: colors.COLORNAME,
  borderAlternativeLevel2: colors.COLORNAME,
  borderAlternativeLevel3: colors.COLORNAME,
  borderAlternativeLevel4: colors.COLORNAME,

  backgroundPrimaryLevel1: colors.COLORNAME,
  backgroundPrimaryLevel2: colors.COLORNAME,
  backgroundPrimaryLevel3: colors.COLORNAME,
  backgroundPrimaryLevel4: colors.COLORNAME,

  backgroundSuccessLevel1: colors.COLORNAME,
  backgroundSuccessLevel2: colors.COLORNAME,
  backgroundSuccessLevel3: colors.COLORNAME,
  backgroundSuccessLevel4: colors.COLORNAME,

  backgroundWarningLevel1: colors.COLORNAME,
  backgroundWarningLevel2: colors.COLORNAME,
  backgroundWarningLevel3: colors.COLORNAME,
  backgroundWarningLevel4: colors.COLORNAME,

  backgroundDangerLevel1: colors.COLORNAME,
  backgroundDangerLevel2: colors.COLORNAME,
  backgroundDangerLevel3: colors.COLORNAME,
  backgroundDangerLevel4: colors.COLORNAME,

  textDefault: colors.N800,
  textDisabled: colors.N100,

  textSubtle: colors.N500,

  textHint: colors.N200,
  textHintDisabled: colors.NA32,

  textLight: 'white',
  textLightDisabled: 'rgba(255, 255, 255, 0.8)',

  textDark: colors.N800,
  textDarkDisabled: colors.N100,

  textPrimary: '#0F4880',
  textPrimaryFocus: colors.B500,
  textPrimaryHover: colors.B500,
  textPrimaryActive: colors.B500,
  textPrimaryDisabled: colors.BA48,

  textInfo: colors.LB500,
  textInfoFocus: colors.LB500,
  textInfoHover: colors.LB500,
  textInfoActive: colors.LB500,
  textInfoDisabled: colors.LBA48,

  textSuccess: colors.G500,
  textSuccessFocus: colors.G500,
  textSuccessHover: colors.G500,
  textSuccessActive: colors.G500,
  textSuccessDisabled: colors.GA48,

  textWarning: colors.Y500,
  textWarningFocus: colors.Y500,
  textWarningHover: colors.Y500,
  textWarningActive: colors.Y500,
  textWarningDisabled: colors.YA48,

  textDanger: colors.R500,
  textDangerFocus: colors.R500,
  textDangerHover: colors.R500,
  textDangerActive: colors.R500,
  textDangerDisabled: colors.RA48,

  outlineColor: colors.COLORNAME
}

export default theme
