import styled from '../../styles'

const TableHeading = styled('th', {
  padding: '0.625rem 0.75rem 0.5rem',

  color: '$textDefault',

  fontSize: '$md',
  fontWeight: 500,
  textAlign: 'left',

  verticalAlign: 'bottom',

  '&:first-child': {
    paddingLeft: '1.5rem'
  },

  '&:last-child': {
    paddingRight: '1.5rem'
  }
})

export default TableHeading
