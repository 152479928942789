import { API, graphqlOperation } from 'aws-amplify';
import useQuery from '../../helpers/useQuery';

import { useEffect } from 'react';
import { syncFitbit } from '../../graphql/mutations';
import Button from '../../elements/Button';
import Text from '../../elements/Text';
import Card, { Body as CardBody, Heading } from '../../elements/Card';
import { useNavigate } from 'react-router-dom';
import Icon from '../../elements/Icon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const FITBIT_AUTHORIZE_URL = 'https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=23B7JH&redirect_uri=https%3A%2F%2Fact2.lovelybyte.com%2Fhealth&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800';

const Link = ({ children, ...props }) => <a {...props}>{children}</a>;

const AuthorizeCard = ({loading}) => {
    const query = useQuery();
    const navigate = useNavigate();

    useEffect(() => {
      const code = query.get('code');
      if (code) API.graphql(graphqlOperation(syncFitbit, { code })).then(() => navigate('/health')).catch(console.error);
    }, [query, navigate]);

    return <Card>
      <Heading>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Text size='xl' weight='medium'>
            Fitbit
          </Text>
        </div>
      </Heading>
      <CardBody>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Text size='m' weight='medium'>
                Connect with your fitbit device to get insights about your health.
            </Text>
            <Button as={Link} color='primary' href={FITBIT_AUTHORIZE_URL} disabled={loading}>
              Authorize&nbsp;{loading && <Icon icon={faSpinner} spin></Icon>}
            </Button>
        </div>
      </CardBody>
    </Card>
}

export default AuthorizeCard;