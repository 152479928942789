import { useField } from '@area2k/use-form'
import { useCallback, useState } from 'react'

import FormElement from '../components/FormElement'
import MaskedInput from '../components/MaskedInput'

import FieldErrors from './FieldErrors'

const MaskedInputField = ({ fieldId, label, incompleteMessage = 'Field value is invalid', ...props }) => {
  const { addError, errors, removeError, value, setValue } = useField(fieldId)

  const [isDirty, setIsDirty] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const hasErrors = Object.keys(errors).length > 0

  const handleBlur = useCallback(() => {
    if (isDirty) {
      if (isComplete || (!props.required && value === '')) {
        removeError('notCompleted')
      } else {
        addError('notCompleted', { message: incompleteMessage })
      }
    }
  }, [isDirty, isComplete, value, incompleteMessage])

  return (
    <FormElement htmlFor={fieldId} label={label}>
      <MaskedInput
        {...props}
        id={fieldId}
        name={fieldId}
        status={hasErrors ? 'danger' : 'basic'}
        value={value}
        onAccept={(value, inputMask) => {
          setIsDirty(true)
          setIsComplete(inputMask.masked.isComplete)
          setValue(value)

          if (inputMask.masked.isComplete || (!props.required && value === '')) {
            removeError('notCompleted')
          }
        }}
        onBlur={handleBlur}
      />
      {hasErrors && <FieldErrors errors={errors} />}
    </FormElement>
  )
}

export default MaskedInputField
