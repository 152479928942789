import { createCss } from '@stitches/react';

import colors from './colors';
import { robotoFont, systemFont } from './typography';

import light from './themes/light';

const { styled, css, global } = createCss({
  theme: {
    colors: {
      ...colors,
      ...light,
      act2Blue: '#0F4880'
    },
    fonts: {
      roboto: robotoFont,
      system: systemFont
    },
    fontSizes: {
      sm: '0.75rem',
      md: '0.875rem',
      lg: '1rem',
      xl: '1.25rem',
      xxl: '1.5rem'
    },
    radii: {
      none: '0px',
      sm: '2px',
      md: '3px',
      lg: '4px',
      xl: '6px',
      round: '9999px'
    },
    shadows: {
      0: 'none',
      1: `0 3px 6px ${colors.$NA8}`,
      2: `0 4px 8px ${colors.$NA12}`,
      3: `0 12px 24px ${colors.$NA12}, 0 4px 8px ${colors.$NA24}`,
      4: `0 16px 32px ${colors.$NA32}, 0 4px 8px ${colors.$NA48}`,
      focus: `0 0 0px 3px ${colors.$BA32}`,
      act2: '0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12)'
    },
    zIndices: {
      sidebar: '1000',
      overlay: '10000',
      navbar: '1001',
      popover: '2000'
    }
  },
  media: {
    mediaPhoneOnly: '(max-width: 559px)',
    mediaTablet: '(min-width: 600px)',
    mediaSmallDesktop: '(min-width: 900px)',
    mediaDesktop: '(min-width: 1200px)',
    mediaLargeDesktop: '(min-width: 1800px)'
  }
})

global({
  '*': {
    boxSizing: 'border-box'
  },
  html: {
    fontSize: '16px',

    '&.font-size-12': {
      fontSize: '12px'
    },
    '&.font-size-14': {
      fontSize: '14px'
    },
    '&.font-size-16': {
      fontSize: '16px'
    },
    '&.font-size-20': {
      fontSize: '20px'
    },
    '&.font-size-24': {
      fontSize: '24px'
    },
  },
  'html, body, #root': {
    display: 'initial',
    height: '100%',
    minHeight: '100%',
    width: '100vw',

    lineHeight: 1.71429,

    WebkitTapHighlightColor: colors.$BA32
  },
  body: {
    margin: 0,
    WebkitOverflowScrolling: 'touch',

    backgroundColor: '$N20',
    color: 'black',
    overflowY: 'auto',

    fontFamily: '$roboto',
    fontWeight: 400,
    textRendering: 'optimizeLegibility',

    '&.noscroll': {
      overflow: 'hidden'
    }
  },
  'p, h1, h2, h3, h4, h5': {
    margin: 0,

    fontSize: '1rem',
    fontWeight: 400
  },
  small: {
    fontSize: '$sm'
  },
  ul: {
    margin: 0,
    padding: 0
  }
})()

export { css }
export default styled;
