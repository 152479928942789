import { useEffect, useState } from "react";
import AuthorizeCard from "./AuthorizeCard";
import { API, graphqlOperation } from 'aws-amplify';
import { queryFitbit } from "../../graphql/queries";
import ProfileCards from "./ProfileCard";
import ActivityCard from "./ActivityCard";
import ActiveChart from "./ActiveChart";
import HeartrateCard from "./HeartrateCard";


const Fitbit = () => {
    const [profile, setProfile] = useState(null);
    useEffect(() => {
        if(!profile) {
            API.graphql(graphqlOperation(queryFitbit))
            .then((res) => {
                return JSON.parse(res?.data?.queryFitbit || '{ success: false }');
            }).then((profile) => {
                setProfile(profile);
                console.log(profile);
            }).catch((e) => {
                console.error(e)
                setProfile({ success: false });
            });
        }
    }, [profile]);

    if (!profile || profile.success === false) return <AuthorizeCard loading={!profile} />;

    return <>
        <ProfileCards profile={profile}></ProfileCards>
        <ActiveChart></ActiveChart>
        <ActivityCard></ActivityCard>
        <ActivityCard activity={'calories'} title={'Burnt Calories'} color={'#FF0000'}></ActivityCard>
        <ActivityCard activity={'distance'} title={'Distance'} color={'#00fa00'}></ActivityCard>
    </>;
};

export default Fitbit;