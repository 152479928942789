import styled from '../styles'

const GUTTER_WIDTH = '1rem'

const Wrapper = styled('main', {
  display: 'grid',
  gridTemplateAreas: '"lgutter content rgutter"',
  gridTemplateColumns: `${GUTTER_WIDTH} 1fr ${GUTTER_WIDTH}`,

  '@mediaSmallDesktop': {
    gridTemplateColumns: '1fr minmax(auto, 40rem) 1fr'
  },

  variants: {
    size: {
      sm: {
        '@mediaSmallDesktop': {
          gridTemplateColumns: '1fr minmax(auto, 32rem) 1fr'
        }
      },
      md: {
        '@mediaSmallDesktop': {
          gridTemplateColumns: '1fr minmax(auto, 40rem) 1fr'
        }
      },
      lg: {
        '@mediaSmallDesktop': {
          gridTemplateColumns: '1fr minmax(auto, 48rem) 1fr'
        }
      },
      xl: {
        '@mediaSmallDesktop': {
          gridTemplateColumns: '1fr minmax(auto, 64rem) 1fr'
        }
      },
      xxl: {
        '@mediaSmallDesktop': {
          gridTemplateColumns: '1fr minmax(auto, 72rem) 1fr'
        }
      }
    }
  }
})

const Content = styled('div', {
  gridArea: 'content'
})

const SingleColumnLayout = ({ children, size = 'md' }) => {
  return (
    <Wrapper size={size}>
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
}

export default SingleColumnLayout
