import { useField, useFormContext } from '@area2k/use-form'
import { useEffect } from 'react'

import Input from '../elements/Input'

import FormElement from '../components/FormElement'
import FieldErrors from './FieldErrors'

const ConfirmPasswordField = () => {
  const { values } = useFormContext()
  const { errors, value, addError, removeError, setValue } = useField('passwordConfirmation')

  useEffect(() => {
    if (value === '' || value === values.password) {
      removeError('passwordMismatch')
    } else {
      addError('passwordMismatch', { message: 'Passwords do not match' })
    }
  }, [values.password, value])

  const hasErrors = Object.keys(errors).length > 0

  return (
    <FormElement
      htmlFor='passwordConfirmation'
      label='Confirm password'
    >
      <Input
        required
        autoComplete='new-password'
        css={{ letterSpacing: '1px' }}
        id='passwordConfirmation'
        name='passwordConfirmation'
        placeholder='&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;'
        status={hasErrors ? 'danger' : 'basic'}
        type='password'
        value={value}
        onChange={(ev) => setValue(ev.currentTarget.value)}
      />
      {hasErrors && <FieldErrors errors={errors} />}
    </FormElement>
  )
}

export default ConfirmPasswordField
