// eslint-disable-next-line
import { faChartLine, faDollarSign, faHeart, faHome, faTachometerAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line
import { faCalendarAlt, faCreditCard } from '@fortawesome/free-regular-svg-icons'

import Group from './Group'
import Item from './Item'

import styled from '../../styles'

const Wrapper = styled('nav', {
  display: 'flex',
  flexDirection: 'column'
})

const Sidebar = ({ children }) => {
  return (
    <Wrapper>
      <Group>
        <Item
          end
          icon={faTachometerAlt}
          title='Dashboard'
          to='/'
        />
        {/*
        <Item
          icon={faDollarSign}
          title='Banking'
          to='/banking'
        />
        <Item
          icon={faChartLine}
          title='Investments'
          to='/investments'
        />
        <Item
          icon={faCreditCard}
          title='Credit cards'
          to='/credit'
        />
        <Item
          icon={faHome}
          title='Mortgages'
          to='/mortgages'
        />
        */}
        <Item
          icon={faHeart}
          title='Health'
          to='/health'
        />
        {/*
        <Item
          icon={faUserFriends}
          title='Observers'
          to='/observers'
        />
        <Item
          icon={faCalendarAlt}
          title='Calendar'
          to='/calendar'
        />
        */}
      </Group>
    </Wrapper>
  )
}

export default Sidebar
