import { Outlet } from 'react-router-dom';

import UnauthenticatedLayout from '../components/UnauthenticatedLayout'

const PublicRoute = () => {
    return (
      <UnauthenticatedLayout displayActions={false}>
        <Outlet />
      </UnauthenticatedLayout>
    )
}

export default PublicRoute