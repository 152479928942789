import { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";

const useAuth = () => {
    const [user, setUser] = useState('Loading');
    useEffect(() => {
      const listener = async (data) => {
        Auth.currentAuthenticatedUser()
          .then((u) => {
            const {exp, iat} = u?.signInUserSession?.accessToken?.payload || {};
            setUser(iat < exp ? u : null);
          })
          .catch(() => setUser(null));
      }
      Hub.listen('auth', listener);
      listener();
      return () => Hub.remove('auth', listener);
    }, []);
  
    return user;
  }

export default useAuth;