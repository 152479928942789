import styled from '../styles'

import Icon from '../elements/Icon'
import Text from '../elements/Text'

const Wrapper = styled('div', {
  display: 'flex',
  padding: '1rem 1.5rem',

  backgroundColor: '$YA32',
  borderRadius: '$md',

  fontSize: '1.25rem',

  variants: {
    status: {
      info: {
        backgroundColor: '$NA4',
      },
      warning: {
        backgroundColor: '$YA32'
      },
      danger: {
        backgroundColor: '$RA16'
      }
    }
  }
})

Wrapper.displayName = 'Alert-Wrapper'

const TextContent = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  paddingLeft: '1.5rem'
})

TextContent.displayName = 'Alert-TextContent'


const Alert = ({ description, icon, title, ...props }) => {
  return (
    <Wrapper {...props}>
      <div>
        <Icon icon={icon} />
      </div>
      <TextContent>
        <Text size='lg' weight='medium'>
          {title}
        </Text>
        <Text>
          {description}
        </Text>
      </TextContent>
    </Wrapper>
  )
}

export default Alert
