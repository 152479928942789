import { useEffect, useState } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { queryFitbit } from "../../graphql/queries";
import Text from '../../elements/Text';
import Card, { Body as CardBody, Heading } from '../../elements/Card';
import Chart from "react-apexcharts";

const ActiveChart = ({ }) => {
  const [activeState, setActiveState] = useState(null);
    useEffect(() => {
        if(!activeState) {
            Promise.all(
                ['minutesSedentary', 'minutesLightlyActive', 'minutesFairlyActive', 'minutesVeryActive'].map(
                    (activity) => API.graphql(graphqlOperation(queryFitbit, {
                        queryType: 'ACTIVITY_DATE',
                        period: '1d',
                        activity,
                    }))
                    .then((res) => {
                        return JSON.parse(res?.data?.queryFitbit || '{ success: false }');
                    })
                )
            )
            .then((activeMinutes) => {
                setActiveState(activeMinutes);
              console.log(activeMinutes);
            }).catch((e) => {
              console.error(e)
              setActiveState({ success: false });
            });
        }
    }, [activeState]);


    if (!activeState) return <></>;

    const { options, series } = activeState.reduce((acc, data, index) => {
        const value = Number((Object.values(data || {}))?.[0]?.[0]?.value);
        acc.series.push(value);
        return acc;
    }, {
      options: {
        chart: {
          id: `active-donut`,
        },
        labels: ['Minutes Sedentary', 'Minutes Lightly Active', 'Minutes Fairly Active', 'Minutes Very Active'],
      },
      series: []
    });

    return <Card>
      <Heading>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Text size='xl' weight='medium'>
            Activity Today
          </Text>
        </div>
      </Heading>
      <CardBody>
        <Chart
          options={options}
          series={series}
          type="donut"
          width="500"
        />
      </CardBody>
    </Card>
}

export default ActiveChart;