import Text from '../elements/Text'

const FieldErrors = ({ errors }) => {
  return (
    <>
      {Object.keys(errors).map((errorKey) => (
        <div key={errorKey} style={{ margin: '0.375rem 0.375rem 0' }}>
          <Text color='danger' size='sm' weight='medium'>
            {errors[errorKey].message}
          </Text>
        </div>
      ))}
    </>
  )
}

export default FieldErrors
