import { Link } from 'react-router-dom'

import styled from '../../styles'

import Button from '../../elements/Button'

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',

  backgroundColor: '$act2Blue',
  boxShadow: '0 1px $NA12',
  color: 'white'
})

Wrapper.displayName = 'Navbar-Wrapper'


const Actions = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  paddingRight: '2rem',

  '> * + *': {
    marginLeft: '1rem'
  }
});

const Logo = styled('img', {
  paddingLeft: '2rem',
  maxHeight: '2.2rem'
});

const UnauthenticatedNavbar = ({ displayActions = true }) => {
  return (
    <Wrapper>
      <Logo src="/act2_logo.png" />
      { displayActions && <Actions>
        <Button as={Link} appearance='outline' color='white' to='/signup'>
          Signup
        </Button>
        <Button as={Link} color='white' to='/login'>
          Login
        </Button>
      </Actions>}
    </Wrapper>
  )
}

export default UnauthenticatedNavbar
