/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryFitbit = /* GraphQL */ `
  query QueryFitbit(
    $queryType: QueryType
    $url: String
    $activity: String
    $period: String
    $date: String
    $to: String
    $detail: String
  ) {
    queryFitbit(
      queryType: $queryType
      url: $url
      activity: $activity
      period: $period
      date: $date
      to: $to
      detail: $detail
    )
  }
`;
