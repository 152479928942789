import { useCallback } from 'react'

import styled from '../../styles'
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Button from '../../elements/Button'
import Card, { Body, Heading } from '../../elements/Card'
import FormFooter from '../../elements/FormFooter'
import Link from '../../elements/Link'
import Text from '../../elements/Text'

import SingleColumnLayout from '../../components/SingleColumnLayout'

import ConfirmPasswordField from '../../form/ConfirmPasswordField'
import Form from '../../form'
import MaskedInputField from '../../form/MaskedInputField'
import TextField from '../../form/TextField'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  phoneNumber: ''
}

const Wrapper = styled('div', {
  padding: '10vh 0'
})

const Signup = () => {
  const navigate = useNavigate();
  const handleSubmit = useCallback(async ({ email, password, passwordConfirmation, phoneNumber }, { setFormError }) => {
    if ( password !== passwordConfirmation ) {
      setFormError('INVALID_PASSWORDS', {
        icon: faLock,
        title: 'Passwords dont match',
        status: 'warning'
      });
      return;
    }
    const phone_number = `+1${phoneNumber.replace(/(\(|\)|\s|-)/g, '')}`;
    console.log(phone_number);
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        phone_number,
      }
    }).then(()=> navigate(`/confirmation?username=${email}`)).catch(({ message }) => setFormError('INVALID_SIGNUP', {
      icon: faLock,
      title: message,
      status: 'warning'
    }));
  }, [])

  return (
    <SingleColumnLayout>
      <Wrapper>
        <Card>
          <Heading>
            <Text size='lg' weight='medium'>
              Sign up
            </Text>
          </Heading>
          <Body>
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <TextField
                required
                autoComplete='email'
                fieldId='email'
                label='Email address'
                placeholder='john.smith@example.net'
                type='email'
              />
              <MaskedInputField
                required
                autoComplete='tel-national'
                fieldId='phoneNumber'
                incompleteMessage='Must be a valid phone number'
                label='Phone number'
                mask='(000) 000-0000'
                placeholder='(555) 555-5555'
                type='tel'
              />
              <TextField
                required
                autoComplete='new-password'
                css={{ letterSpacing: '1px' }}
                fieldId='password'
                label='Password'
                placeholder='&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;'
                type='password'
              />
              <ConfirmPasswordField />
              <FormFooter>
                <Link to='/login' size='lg'>
                  Have an account? <span style={{ textDecoration: 'underline' }}>Sign in</span>
                </Link>
                <Button type='submit'>
                  Continue
                </Button>
              </FormFooter>
            </Form>
          </Body>
        </Card>
      </Wrapper>
    </SingleColumnLayout>
  )
}

export default Signup
