import { useCallback } from 'react'

import styled from '../../styles'

import Button from '../../elements/Button'
import Card, { Body, Heading } from '../../elements/Card'
import Text from '../../elements/Text'

import SingleColumnLayout from '../../components/SingleColumnLayout'
import Form from '../../form'
import TextField from '../../form/TextField'
import useQuery from '../../helpers/useQuery'

import { Auth } from 'aws-amplify'


const Wrapper = styled('div', {
  padding: '10vh 0'
})


const initialValues = { code: '' }

const Confirmation = () => {
  const query = useQuery();

  const handleSubmit = useCallback(async ({ code }, { setFormError }) => {
    const username = query.get('username');
    await Auth.confirmSignUp(username, code).catch(({ message }) => setFormError('VALIDATION_ERROR', {
      title: message,
      status: 'warning'
    }));
  }, [])

  return (
    <SingleColumnLayout size='sm'>
      <Wrapper>
        <Card>
          <Heading>
            <Text size='lg' weight='medium'>
              We sent an SMS to your phone
            </Text>
          </Heading>
          <Body>
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <TextField
                required
                fieldId='code'
                label='Code'
                placeholder='123456'
                type='text'
              />
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
                <Button>
                  Submit
                </Button>
              </div>
            </Form>
          </Body>
        </Card>
      </Wrapper>
    </SingleColumnLayout>
  )
}

export default Confirmation
