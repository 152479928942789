import React from 'react'

import styled from '../../styles'

import UnauthenticatedNavbar from './UnauthenticatedNavbar'

const NAVBAR_HEIGHT = '4rem'
const MOBILE_NAVBAR_HEIGHT = '4rem'

const Wrapper = styled('main', {
  display: 'grid',
  gridTemplateAreas: '"navbar" "content"',
  gridTemplateColumns: '100vw',
  gridTemplateRows: `${MOBILE_NAVBAR_HEIGHT} 1fr`,
  height: '100%',

  overflowX: 'hidden',

  '@mediaSmallDesktop': {
    gridTemplateRows: `${NAVBAR_HEIGHT} 1fr`
  }
})

const NavbarContainer = styled('nav', {
  display: 'flex',
  gridArea: 'navbar',

  zIndex: '$navbar',

  '> *': {
    flex: 1
  },

  '@mediaSmallDesktop': {
    height: NAVBAR_HEIGHT,

    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
  }
})

const UnauthenticatedLayout = ({ children, displayActions }) => {
  return (
    <Wrapper>
      <NavbarContainer>
        <UnauthenticatedNavbar displayActions={displayActions} />
      </NavbarContainer>
      <section style={{ gridArea: 'content' }}>
        {children}
      </section>
    </Wrapper>
  )
}

export default UnauthenticatedLayout
