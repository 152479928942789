import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import NumberFormat from 'react-number-format';

export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

 export const tableOptions = {
    search: false,
    columnsButton: true,
    showTitle: false,
    paging: false,
  }

const renderNumber = (field, prefix = '$') => (props) => <NumberFormat
  value={typeof field === 'function' ? field?.(props) : props?.[field]}
  displayType={'text'}
  thousandSeparator={true}
  prefix={prefix}
  renderText={(value, props) => <div {...props}>{value}</div>}
/>;

export const tableColumns = () => [
    { field: 'service' },
    { title: '# of members', field: 'length', align: 'right', render: renderNumber('length', false) },
    { title: 'Average', field: 'avg', hidden: true, hiddenByColumnsButton: false, align: 'right', render: renderNumber('avg') },
    { title: 'Median', field: 'median', align: 'right', render: renderNumber(({ low, avg }) => (low+avg)/2)},
    { title: 'Low', field: 'low', align: 'right', render: renderNumber('low') },
    { title: 'High', field: 'high', align: 'right', render: renderNumber('high') },
    { title: 'Average # of transactions', field: 'avgTransactions', hidden: true, hiddenByColumnsButton: false, align: 'right', render: renderNumber(({ length }) => (length*0.05).toFixed(0)) },
  ]