

import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faBell, faHeart, faTh } from '@fortawesome/free-solid-svg-icons';

import styled from '../../styles';

import Card, { Body as CardBody, Heading } from '../../elements/Card';
import Icon from '../../elements/Icon';
import Table, { TableCell, TableRow } from '../../elements/Table';
import Text from '../../elements/Text';

import SingleColumnLayout from '../../components/SingleColumnLayout';
import Fitbit from '../../components/Fitbit';

const TwoColumnGrid = styled('div', {
  display: 'grid',
  gap: '1.5rem',
  gridTemplateColumns: 'repeat(2, 1fr)',
  paddingTop: '2rem'
});

const Pill = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '0.75rem',
  padding: '0.5rem 0.75rem',

  backgroundColor: '$NA12',
  borderRadius: '$round'
});

const Health = () => {
  return (
    <SingleColumnLayout size='xl'>
      <TwoColumnGrid>
        <Fitbit/>
      </TwoColumnGrid>
    </SingleColumnLayout>
  )
}

export default Health
