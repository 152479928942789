import styled from '../styles'

import Table from './Table'

const Card = styled('div', {
  backgroundColor: '$N0',
  borderRadius: '$xl',
  boxShadow: '$act2',

  [`> ${Table}`]: {
    paddingBottom: '64px'
  }
})

const Heading = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1rem 1.5rem',

  borderBottom: '1px solid $NA12',
  borderRadius: '$xl $xl 0 0',

  variants: {
    appearance: {
      default: {
        backgroundColor: 'white',
        borderColor: '$NA12',
        color: '$textDefault'
      },
      highlight: {
        backgroundColor: '$act2Blue',
        borderColor: '$act2Blue',
        color: 'white'
      }
    }
  }
})

const Body = styled('div', {
  padding: '1rem 1.5rem 1.25rem',

  '&:first-child': {
    paddingTop: '1rem'
  }
})

Card.displayName = 'Card'
Heading.displayName = 'Card-Heading'
Body.displayName = 'Card-Body'

export { Body, Heading }
export default Card

// #177BED
