import { NavLink } from 'react-router-dom';

import styled from '../../styles';

import Icon from '../../elements/Icon';

const Wrapper = styled(NavLink, {
  alignItems: 'center',
  display: 'flex',
  padding: '1rem 1.75rem',

  backgroundColor: 'transparent',
  color: '$textDefault',

  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  textDecoration: 'none',

  '& + &': {
    marginTop: '0.5rem'
  },

  '&:hover': {
    backgroundColor: '$NA4'
  },

  '&.active': {
    backgroundColor: '#AFD7FF',
    color: '$textDefault',

    '&:hover': {
      backgroundColor: '#AFD7FF'
    }
  },

  [`> ${Icon}`]: {
    marginRight: '1.75rem',
    width: '1.5rem',

    fontSize: '1.5rem',
    lineHeight: '1.5rem'
  },

  variants: {
    status: {
      active: {
        backgroundColor: '$NA8',
        boxShadow: 'inset 3px 0 0 $B500',
        color: '$textDefault',

        '&:hover': {
          backgroundColor: '$NA12'
        }
      }
    }
  }
})

const Item = ({ icon, title, ...props }) => {
  return (
    <Wrapper {...props}>
      <Icon icon={icon} />
        {title}
    </Wrapper>
  )
}

export default Item
