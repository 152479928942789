import { IMaskMixin } from 'react-imask'

import Input from '../elements/Input'

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => (
  <Input
    ref={inputRef}
    {...props}
  />
))

export default MaskedInput
