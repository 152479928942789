import { Link as ReactRouterLink } from 'react-router-dom'

import styled from '../styles'

const Link = styled(ReactRouterLink, {
  color: '$B500',

  fontSize: '$md',
  lineHeight: 1.71429,
  textDecoration: 'none',

  '&:hover': {
    color: '$B500',

    textDecoration: 'underline'
  },

  variants: {
    appearance: {
      hidden: {
        color: '$textDefault'
      },
      subtle: {
        color: '$textSubtle'
      },
      hint: {
        color: '$textHint'
      }
    },
    size: {
      sm: {
        fontSize: '$sm'
      },
      lg: {
        fontSize: '$lg',
        lineHeight: '28px'
      },
      xl: {
        fontSize: '$xl',
        lineHeight: '28px'
      },
      xxl: {
        fontSize: '$xxl',
        lineHeight: '28px'
      },
      inherit: {
        fontSize: 'inherit',
        lineHeight: 'inherit'
      }
    }
  }
})

Link.displayName = 'Link'

export default Link
