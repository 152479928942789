import Text from '../../elements/Text';
import Card, { Body as CardBody, Heading } from '../../elements/Card';

const ProfileCards = ({ profile = {}}) => {
    const { user } = profile;
    const { topBadges = [], displayName, averageDailySteps, avatar } = user;
    return <Card>
      <Heading>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Text size='xl' weight='medium'>
            Fitbit Profile
          </Text>
        </div>
      </Heading>
      <CardBody>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Text size='xl' weight='medium'>{displayName}</Text>
            <Text size='l'>Average Daily Steps: {averageDailySteps}</Text>
            {/*topBadges?.length 
              ? <div style={{ display: 'flex', alignItems: 'center', gap: '1rem'}}>
                  <Text size='l' >Top Badges</Text>
                  {topBadges.map(({ name, image100px, description }) => <img src={image100px} alt={description} title={name} style={{maxHeight: '4rem' }}></img>)}
                </div> 
              : null
            */}
        </div>
      </CardBody>
    </Card>
}

export default ProfileCards;