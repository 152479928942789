import { useCallback, useState } from 'react'

import styled from '../../styles'

import Button from '../../elements/Button'
import Card, { Body, Heading } from '../../elements/Card'
import Link from '../../elements/Link'
import Text from '../../elements/Text'

import SingleColumnLayout from '../../components/SingleColumnLayout'
import Form from '../../form'
import TextField from '../../form/TextField'

import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'


const Wrapper = styled('div', {
  padding: '10vh 0'
})


const initialValues = { email: '', code: '', newPassword: '' };

const ForgotPassword = () => {
  const [didSubmit, setDidSubmit] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = useCallback(async ({ email, code, newPassword }, { setFormError }) => {
    if(!code) {
        Auth.forgotPassword(email)
            .then(data => setDidSubmit(true))
            .catch(({ message }) => setFormError('INVALID_CODE', {
                title: message,
                status: 'warning'
            }));
    } else {
        Auth.forgotPasswordSubmit(email, code, newPassword)
        .then(data => navigate('/login'))
        .catch(({ message }) => setFormError('INVALID_CODE', {
            title: message,
            status: 'warning'
        }));
    }
    
  }, [])

  return (
    <SingleColumnLayout size='sm'>
      <Wrapper>
        <Card>
          <Heading>
            <Text size='lg' weight='medium'>
              Forgot password?
            </Text>
          </Heading>
          <Body>
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
                <TextField
                      required
                      autoComplete='email'
                      fieldId='email'
                      label='Email address'
                      placeholder='Email address'
                      type='email'
                    />
                    
              {didSubmit && <>
                <TextField
                      required
                      fieldId='code'
                      label='Code'
                      placeholder='123456'
                      type='text'
                    />
                <TextField
                      required
                      fieldId='newPassword'
                      label='New Password'
                      placeholder='******'
                      type='password'
                    />
                  </>
              }
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
                      <Button>
                        Submit
                      </Button>
                    </div>
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginTop: '1.5rem' }}>
                <Link to='/login' size='lg'>
                  &larr; Back to login
                </Link>
                <Link to='/signup' size='lg'>
                  Don't have an account? <span style={{ textDecoration: 'underline' }}>Sign up</span>
                </Link>
              </div>
            </Form>
          </Body>
        </Card>
      </Wrapper>
    </SingleColumnLayout>
  )
}

export default ForgotPassword
