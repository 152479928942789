import FormProvider from '@area2k/use-form'
import { useCallback } from 'react'

import FormErrorAlerts from './FormErrorAlerts'

const Form = ({ children, onSubmitWithErrors, ...props }) => {
  const handleSubmitWithErrors = useCallback(async (values, errors, helpers) => {
    if (onSubmitWithErrors) return onSubmitWithErrors(values, errors, helpers)

    helpers.setFormError('fieldErrors', {
      title: 'Form contains errors',
      message: 'Please fix the highlighted fields to continue.',
      status: 'danger'
    })
  }, [onSubmitWithErrors])

  return (
    <FormProvider
      {...props}
      onSubmitWithErrors={handleSubmitWithErrors}
    >
      <FormErrorAlerts />
      {children}
    </FormProvider>
  )
}

export default Form
