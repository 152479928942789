import React from 'react';
import './App.css';
import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import Login from './routes/Login';
import Health from './routes/Health';
import Analytics from './routes/Analytics';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import UnauthenticatedLayoutRoute from './routes/UnauthenticatedLayoutRoute';
import MainLayoutRoute from './routes/MainLayoutRoute';
import PublicRoute from './routes/PublicRoute';
import Signup from './routes/Signup';
import ForgotPassword from './routes/ForgotPassword';
import Confirmation from './routes/Confirmation';

Amplify.configure(awsExports);
Auth.configure(awsExports);

function App() {
  return (
    <Router className="App">
      <Routes>
        <Route element={<MainLayoutRoute />}>
          <Route element={<div />} path='/' />
          <Route element={<Health />} path='/health' />
        </Route>
        <Route element={<UnauthenticatedLayoutRoute />}>
          <Route element={<Login />} path='/login' />
          <Route element={<Signup />} path='/signup' />
          <Route element={<ForgotPassword />} path='/forgot-password' />
          <Route element={<Confirmation />} path='/confirmation' />
        </Route>
        <Route element={<PublicRoute />}>
          <Route element={<Analytics/>} path='/analytics' />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
