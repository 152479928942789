import { useEffect, useState } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { queryFitbit } from "../../graphql/queries";
import Text from '../../elements/Text';
import Card, { Body as CardBody, Heading } from '../../elements/Card';
import Chart from "react-apexcharts";
import moment from 'moment';

const ActivityCard = ({ activity = 'steps', title = 'Steps', color, decimals = 1 }) => {
  const [activityState, setActivityState] = useState(null);
    useEffect(() => {
        if(!activityState) {
            API.graphql(graphqlOperation(queryFitbit, {
              queryType: 'ACTIVITY_DATE',
              period: '1w',
              activity,
            }))
            .then((res) => {
                return JSON.parse(res?.data?.queryFitbit || '{ success: false }');
            }).then((activity) => {
              setActivityState(activity);
              console.log(activity);
            }).catch((e) => {
              console.error(e)
              setActivityState({ success: false });
            });
        }
    }, [activityState]);


    if (!activityState) return <></>;


    const { options, series } = activityState?.[`activities-${activity}`].reduce((acc, { dateTime, value }, index) => {
      const dayOfTheWeek = moment(dateTime).format('dddd');
      acc.options.xaxis.categories.push(index === 6 ? 'Today' : dayOfTheWeek);
      acc.series[0].data.push(Number.isInteger(value) ? value : Number(value).toFixed(decimals));
      return acc;
    }, {
      options: {
        chart: {
          id: `${activity}-bar`,
          forceNiceScale: true,
        },
        xaxis: {
          categories: []
        },
      },
      series: [
        {
          name: activity,
          data: [],
          color,
        }
      ],
    });

    return <Card>
      <Heading>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Text size='xl' weight='medium'>
            {title}
          </Text>
        </div>
      </Heading>
      <CardBody>
        <Chart
          options={options}
          series={series}
          type="bar"
          width="500"
        />
      </CardBody>
    </Card>
}

export default ActivityCard;