/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:1ba6b0e1-0ac2-4ee7-8f95-e297add6eeca",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_vyesVr4AC",
    "aws_user_pools_web_client_id": "1u7kn2s6k52btij2pgidl6fscf",
    "oauth": {
        "domain": "act2.auth.us-west-2.amazoncognito.com"
    },
    "aws_appsync_graphqlEndpoint": "https://2r25dvb4ufduxpscnxpotpl7pq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "fitbitTokens-dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
