import { useField } from '@area2k/use-form'

import Input from '../elements/Input'

import FormElement from '../components/FormElement'


const TextField = ({ fieldId, label, ...props }) => {
  const { value, setValue } = useField(fieldId)

  return (
    <FormElement htmlFor={fieldId} label={label}>
      <Input
        {...props}
        id={fieldId}
        name={fieldId}
        value={value}
        onChange={(ev) => setValue(ev.currentTarget.value)}
      />
    </FormElement>
  )
}

export default TextField
