import styled from '../styles'

import Button from '../elements/Button'
import { Auth } from 'aws-amplify'

const Wrapper = styled('div', {
  display: 'flex',

  backgroundColor: '$act2Blue',
  boxShadow: '0 1px $NA12',
  color: 'white',
  alignItems: 'center',
})

Wrapper.displayName = 'Navbar-Wrapper'


const Actions = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  paddingRight: '2rem',

  '> * + *': {
    marginLeft: '1rem'
  }
})

const Logo = styled('img', {
  paddingLeft: '2rem',
  maxHeight: '2.2rem'
});

const Navbar = () => {
  return (
    <Wrapper>
      <Logo src="/act2_logo.png" />
      <Actions>
        <Button appearance='outline' color='white' onClick={() => Auth.signOut()}>
          Logout
        </Button>
      </Actions>
    </Wrapper>
  )
}

export default Navbar
