import React from 'react'

import Text from '../elements/Text'

import styled from '../styles'

const Wrapper = styled('footer', {
  alignItems: 'center',
  display: 'flex',
  gridArea: 'footer',
  justifyContent: 'space-between',
  padding: '2rem'
})

const Footer = () => {
  return (
    <Wrapper>
      <Text color='hint' size='sm'>
        &copy; 2020 Act2 Financial
      </Text>
    </Wrapper>
  )
}

export default Footer
