import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import styled from '../../styles';
import Text from '../../elements/Text';
import Card, { Body as CardBody, Heading } from '../../elements/Card';
import { tableOptions, tableIcons, tableColumns } from './table.config';
import { Chip } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const mockDataInternal = [
  {
    service: 'Checking Accounts',
    length: 395,
    avg: 2000,
    low: 881,
    high: 3416,
  },
  {
    service: 'Savings Accounts',
    length: 437,
    avg: 5000,
    low: 1981,
    high: 5326,
  },
  {
    service: 'Car Loans',
    length: 33,
    avg: 5000,
    low: 182,
    high: 9401,
  },
  {
    service: 'Mortgages',
    length: 78,
    avg: 200000,
    low: 7750,
    high: 229778,
  },
  {
    service: 'Home Equity Loans',
    length: 49,
    avg: 50000,
    low: 32973,
    high: 89216,
  },
  {
    service: 'Certificates of Deposit',
    length: 357,
    avg: 10000,
    low: 5933,
    high: 15499,
  },
  {
    service: 'IRA Banking Products',
    length: 10,
    avg: 15000,
    low: 14764,
    high: 27666,
  }
];


const mockDataExternal = [
  {
    service: 'Checking Accounts',
    length: 191,
    avg: 1110,
    low: 1072,
    high: 2047,
    mostCommon: 'Connexus'
  },
  {
    service: 'Savings Accounts',
    length: 464,
    avg: 5000,
    low: 4078,
    high: 7880,
    mostCommon: 'Alliant',
  },
  {
    service: 'Car Loans',
    length: 466,
    avg: 7500,
    low: 1498,
    high: 13210,
    mostCommon: 'Alliant'
  },
  {
    service: 'Mortgages',
    length: 58,
    avg: 5000,
    low: 1643,
    high: 8588,
    mostCommon: 'Alliant'
  },
  {
    service: 'Home Equity Loans',
    length: 316,
    avg: 15000,
    low: 6383,
    high: 22861,
    mostCommon: 'Consumers',
  },
  {
    service: 'Certificates of Deposit',
    length: 1,
    avg: 7435,
    low: 7435,
    high: 7435,
    mostCommon: 'Consumers',
  },
  {
    service: 'IRA Banking Products',
    length: 248,
    avg: 11328,
    low: 3316,
    high: 12927,
    mostCommon: 'First Tech',
  }
];

const mockDataExternalBanking = [
  {
    service: 'Checking Accounts',
    length: 191,
    avg: 1110,
    low: 1072,
    high: 2047,
    mostCommon: 'Camden National Bank'
  },
  {
    service: 'Savings Accounts',
    length: 464,
    avg: 5000,
    low: 4078,
    high: 7880,
    mostCommon: 'Camden National Bank',
  },
  {
    service: 'Car Loans',
    length: 466,
    avg: 7500,
    low: 1498,
    high: 13210,
    mostCommon: 'Bangor Savings Bank'
  },
  {
    service: 'Mortgages',
    length: 58,
    avg: 5000,
    low: 1643,
    high: 8588,
    mostCommon: 'Bangor Savings Bank'
  },
  {
    service: 'Home Equity Loans',
    length: 316,
    avg: 15000,
    low: 6383,
    high: 22861,
    mostCommon: 'Camden National Bank',
  },
  {
    service: 'Certificates of Deposit',
    length: 1,
    avg: 7435,
    low: 7435,
    high: 7435,
    mostCommon: 'TD Bank',
  },
  {
    service: 'IRA Banking Products',
    length: 248,
    avg: 11328,
    low: 3316,
    high: 12927,
    mostCommon: 'KeyBank',
  }
];

const TwoColumnGrid = styled('div', {
  display: 'grid',
  gap: '1.5rem',
  gridTemplateColumns: 'repeat(2, 1fr)',
  padding: '2rem'
});

const SevenColumnGrid = styled('div', {
  display: 'grid',
  gap: '1.5rem',
  gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
  paddingTop: '1.5rem',
  gridColumnStart: 1, gridColumnEnd: 3,
});

const Views = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  paddingRight: '2rem',

  '& div': {
    marginLeft: '1rem',
  }
})

const CU_MAP = {
  'Connexus': '/cus/connexus.png',
  'Alliant': '/cus/alliant.png',
  'Consumers': '/cus/consumers.png',
  'First Tech': '/cus/firsttech.png',
  'Golden 1': '/cus/golden1.png',
  'Camden National Bank': '/banks/camden-national-bank.jpeg',
  'Bangor Savings Bank': '/banks/bangor-savings-bank.jpeg',
  'Katahdin Trust Company': '/banks/katahdin-trust-company.jpeg',
  'KeyBank': '/banks/keybank.jpeg',
  "People's United Bank": '/banks/peoples-united-bank.jpeg',
  'TD Bank': '/banks/td-bank.jpeg',
  'Machias Savings Bank': '/banks/machias-savings-bank.jpeg',
  'Norway Savings Bank': '/banks/norway-savings-bank.jpeg',
}

const renderCU = ({ name, mostCommon }) => <img width="100px" src={CU_MAP[name || mostCommon]} title={name || mostCommon} />

const columnsInternal = tableColumns();
const columnsExternal = [...(tableColumns()), { title: 'Most Common', field: 'mostCommon', render: renderCU }];


const renderNumber = (fieldName, prefix = '$') => (props) => <NumberFormat
  value={props?.[fieldName]}
  displayType={'text'}
  thousandSeparator={true}
  prefix={prefix}
  renderText={(value, props) => <div {...props}>{value}</div>}
/>;

const mostCommonColumns = [{ field: 'name', render: renderCU, fixed: true }, { field: 'length', title: '# of members', render: renderNumber('length', false)}, { field: 'cumulativeBalance', title: 'Cumulative Balance', render: renderNumber('cumulativeBalance')}];
const mostCommonOptions = {...tableOptions, showTitle: true, columnsButton: false};
const mostCommonProps = [{
  data: [{ name: 'Connexus', length: 299, cumulativeBalance: 3737500},{ name: 'Alliant', length: 121, cumulativeBalance: 1149500},{ name: 'Consumers', length: 85, cumulativeBalance: 966000},{ name: 'First Tech', length: 69, cumulativeBalance: 731000},{ name: 'Golden 1', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Checking Accounts",
},
{
  data: [{ name: 'Alliant', length: 299, cumulativeBalance: 3737500},{ name: 'Consumers', length: 121, cumulativeBalance: 1149500},{ name: 'Connexus', length: 85, cumulativeBalance: 966000},{ name: 'First Tech', length: 69, cumulativeBalance: 731000},{ name: 'Golden 1', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Savings Accounts",
},
{
  data: [{ name: 'Alliant', length: 299, cumulativeBalance: 3737500},{ name: 'First Tech', length: 121, cumulativeBalance: 1149500},{ name: 'Golden 1', length: 85, cumulativeBalance: 966000},{ name: 'Consumers', length: 69, cumulativeBalance: 731000},{ name: 'Connexus', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Car Loans",
},
{
  data: [{ name: 'Alliant', length: 299, cumulativeBalance: 3737500},{ name: 'Consumers', length: 121, cumulativeBalance: 1149500},{ name: 'Connexus', length: 85, cumulativeBalance: 966000},{ name: 'First Tech', length: 69, cumulativeBalance: 731000},{ name: 'Golden 1', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Mortgages",
},
{
  data: [{ name: 'Consumers', length: 299, cumulativeBalance: 3737500 },{ name: 'Alliant', length: 121, cumulativeBalance: 1149500},{ name: 'Connexus', length: 85, cumulativeBalance: 966000},{ name: 'First Tech', length: 69, cumulativeBalance: 731000},{ name: 'Golden 1', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Home Equity Loans",
},
{
  data: [{ name: 'Consumers', length: 299, cumulativeBalance: 3737500},{ name: 'Alliant', length: 121, cumulativeBalance: 1149500},{ name: 'Golden 1', length: 85, cumulativeBalance: 966000},{ name: 'Connexus', length: 69, cumulativeBalance: 731000},{ name: 'First Tech', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Certificates of Deposit",
},
{
  data: [{ name: 'First Tech', length: 299, cumulativeBalance: 3737500},{ name: 'Alliant', length: 121, cumulativeBalance: 1149500},{ name: 'Consumers', length: 85, cumulativeBalance: 966000},{ name: 'Golden 1', length: 69, cumulativeBalance: 731000},{ name: 'Connexus', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "IRA Banking Products",
}];

const mostCommonPropsBanking = [{
  data: [{ name: 'Camden National Bank', length: 299, cumulativeBalance: 3737500},{ name: 'Bangor Savings Bank', length: 121, cumulativeBalance: 1149500},{ name: 'TD Bank', length: 85, cumulativeBalance: 966000},{ name: 'KeyBank', length: 69, cumulativeBalance: 731000},{ name: "People's United Bank", length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Checking Accounts",
},
{
  data: [{ name: 'Camden National Bank', length: 299, cumulativeBalance: 3737500},{ name: 'TD Bank', length: 121, cumulativeBalance: 1149500},{ name: 'Bangor Savings Bank', length: 85, cumulativeBalance: 966000},{ name: "People's United Bank", length: 69, cumulativeBalance: 731000},{ name: 'KeyBank', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Savings Accounts",
},
{
  data: [{ name: 'Bangor Savings Bank', length: 299, cumulativeBalance: 3737500},{ name: 'Camden National Bank', length: 121, cumulativeBalance: 1149500},{ name: 'KeyBank', length: 85, cumulativeBalance: 966000},{ name: 'TD Bank', length: 69, cumulativeBalance: 731000},{ name: 'Norway Savings Bank', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Car Loans",
},
{
  data: [{ name: 'Bangor Savings Bank', length: 299, cumulativeBalance: 3737500},{ name: 'TD Bank', length: 121, cumulativeBalance: 1149500},{ name: 'Camden National Bank', length: 85, cumulativeBalance: 966000},{ name: 'Norway Savings Bank', length: 69, cumulativeBalance: 731000},{ name: 'KeyBank', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Mortgages",
},
{
  data: [{ name: 'Camden National Bank', length: 299, cumulativeBalance: 3737500 },{ name: 'KeyBank', length: 121, cumulativeBalance: 1149500},{ name: 'Bangor Savings Bank', length: 85, cumulativeBalance: 966000},{ name: 'Norway Savings Bank', length: 69, cumulativeBalance: 731000},{ name: 'TD Bank', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Home Equity Loans",
},
{
  data: [{ name: 'TD Bank', length: 299, cumulativeBalance: 3737500},{ name: 'Camden National Bank', length: 121, cumulativeBalance: 1149500},{ name: 'KeyBank', length: 85, cumulativeBalance: 966000},{ name: 'Bangor Savings Bank', length: 69, cumulativeBalance: 731000},{ name: 'Katahdin Trust Company', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "Certificates of Deposit",
},
{
  data: [{ name: 'KeyBank', length: 299, cumulativeBalance: 3737500},{ name: 'Machias Savings Bank', length: 121, cumulativeBalance: 1149500},{ name: 'Camden National Bank', length: 85, cumulativeBalance: 966000},{ name: 'TD Bank', length: 69, cumulativeBalance: 731000},{ name: 'Bangor Savings Bank', length: 68, cumulativeBalance: 443700}],
  columns: mostCommonColumns,
  options: mostCommonOptions,
  icons: tableIcons,
  title: "IRA Banking Products",
}];

const Analytics = () => {
  const [selected, setSelected] = useState(null);
  const [view, setView] = useState('Credit Unions');
  useEffect(() => {
    if (selected) {
      console.log(selected);
      document.getElementById('popular')?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [selected]);
  return <TwoColumnGrid onClick={() => setSelected(null)}>
    <Text size='xxl' weight='bold' >
      Sponsor Analytics
    </Text>
    <Views>
      <Chip color="primary" label={"Credit Unions"} variant={ view === 'Credit Unions' ? 'default' : 'outlined'} onClick={() => setView('Credit Unions')} />
      <Chip color="primary" label={"Banks"} variant={ view === 'Banks' ? 'default' : 'outlined'} onClick={() => setView('Banks')} />
    </Views>
    <Text size='xl' weight='medium' style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
      Number of signups through CU: 500
    </Text>
    <Card>
      <Heading>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Text size='xl' weight='medium'>
            What do they do with CU
          </Text>
        </div>
      </Heading>
      <CardBody>
        <MaterialTable
          columns={columnsInternal}
          data={mockDataInternal}
          options={tableOptions}
          icons={tableIcons}
        />
      </CardBody>
    </Card>
    <Card>
      <Heading>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Text size='xl' weight='medium'>
            {view === 'Credit Unions' ? 'Products Held Other Credit Unions' : 'Products Held Other Financial Institutions'}
          </Text>
        </div>
      </Heading>
      <CardBody>
        <MaterialTable
          columns={columnsExternal}
          data={view === 'Credit Unions' ? mockDataExternal : mockDataExternalBanking}
          options={tableOptions}
          icons={tableIcons}
          onRowClick={(a, { service }) => setSelected(service)}
        />
      </CardBody>
    </Card>
    <Text size='xl' weight='medium' style={{ gridColumnStart: 1, gridColumnEnd: 3, paddingTop: '3rem' }}>
      Top 5 Member Away Products
    </Text>
    <SevenColumnGrid id={'popular'}>
      {(view === 'Credit Unions' ? mostCommonProps : mostCommonPropsBanking)?.map((props) => <MaterialTable {...props} style={selected === props?.title ?  { border: '1px solid #0F4880' } : { opacity: selected ? 0.5 : 1 }}/>)}
    </SevenColumnGrid>
  </TwoColumnGrid>
}

export default Analytics